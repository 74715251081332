import React from "react";
import { Link } from "gatsby";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Layout from "@components/layouts/Layout";
import rankings from "../../data/Rankings";

const filteredRankings = rankings.filter(item => item.enabled);

const Component = () => {
    return (
        <>
            <Layout
                seo={{
                    title: "¡Los mejores de iOpos!",
                    description:
                        "Nuestros jugadores compiten cada día por demostrar quién es el mejor. Aquí puedes ver los rankings de los usuarios que han completado más tests, obtenido las mejores puntuaciones y dominado nuestras pruebas. Cada semana actualizamos las posiciones, ¡y el primer lugar está esperando por ti!",
                    image: "/images/bgs/bgRank4.jpg",
                }}
            >
                <SmallBanner
                    title="¡Los mejores de iOpos!"
                    subtitle="¿Tienes lo que se necesita para ser el número uno?"
                    filename={"bgs/bgRank4"}
                />

                <div className="container pt-20">
                    <Title>¿Quiénes son los mejores de iOpos Academia?</Title>
                    <Text>
                        Nuestros jugadores compiten cada día por demostrar quién es el mejor. Aquí
                        puedes ver los rankings de los usuarios que han completado más tests,
                        obtenido las mejores puntuaciones y dominado nuestras pruebas. Cada semana
                        actualizamos las posiciones, ¡y el primer lugar está esperando por ti!
                    </Text>
                </div>

                <div className="container lg:px-0 pt-10 pb-20">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                        {filteredRankings?.map(item => (
                            <RenderRanking key={item.id} item={item} />
                        ))}
                    </div>
                </div>
            </Layout>
        </>
    );
};

const RenderRanking = ({ item }) => {
    return (
        <Link
            href={item.link}
            className="bg-gray-200 h-32 rounded-md aspect-w-4 aspect-h-3 relative overflow-hidden shadow-lg flex"
        >
            {item.image && (
                <img
                    src={item.image}
                    className="w-full h-full object-cover absolute z-0"
                    alt={item.title}
                />
            )}

            {item.image && (
                <div className="absolute">
                    <img
                        src="/images/rankings/barres.png"
                        className="w-24 h-24 md:w-32 md:h-32 object-cover absolute z-0 bottom-0 right-0"
                        alt={item.title}
                    />
                </div>
            )}

            <div className="z-20 absolute">
                <h1 className="font-semibold bg-ccyan text-white bg-opacity-50 w-full px-5 py-3 absolute bottom-0">
                    {item.title}
                </h1>
            </div>
        </Link>
    );
};

export default Component;
